<template>
    <div v-if="isBusy">
        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
        <md-dialog :md-active.sync="isBusy" :style="[dialogDimensions,dialogHeight]" :md-fullscreen="false" class="free-dialog" ref="dialog" :md-backdrop="false" >
            <md-dialog-title>
                Upload status
            </md-dialog-title>
            <div class="modal-up-content text-center">
                <md-list v-if="upload.uploadedDocs.length > 0">
                    <md-list-item v-for="(docFile,index) in upload.uploadedDocs" v-bind:key="docFile + index">
                        <md-icon>done</md-icon>
                        <span class="md-list-item-text">{{docFile}}</span>
                    </md-list-item>
                </md-list>
                <div>
                    <md-divider class="mb-4"></md-divider>
                    <p class="md-subheading">{{upload.currentTitle}}</p>
                    <md-progress-bar class="mb-2" md-mode="determinate" :md-value="upload.currentFileProgress"></md-progress-bar>
                    <div class="md-caption mb-4">{{upload.actualBytes}} uploaded of {{upload.totalBytes}} bytes</div>
                </div>
            </div>
        </md-dialog>
            
    </div>
    <div v-else>
        <div v-if="!fleetExists">
            <md-empty-state
                md-icon="drive_eta"
                md-label="To create driver please add at least one vehicle to your fleet"
                md-description="New vehicles will be available for future driver assigments">
                <md-button class="md-primary md-raised" @click="newVehicle()">Add new vehicle</md-button>
            </md-empty-state>
        </div>
        <div v-else>
            <md-toolbar class="md-primary mb-2" md-elevation="1">
                <h3 class="md-title" style="flex: 1" v-if="driver.general.name || driver.general.lastname">{{driver.general.name}} {{driver.general.lastname}}</h3>
                <h3 class="md-title" style="flex: 1" v-else>Driver name</h3>
            
                
                <!--md-button class="md-primary">Save vehicle type</md-button-->
                <md-button class="md-primary md-raised" @click="fireCreate" :disabled="isBusy">Save driver</md-button>
            </md-toolbar>
            <form enctype="multipart/form-data">
                <md-steppers @md-changed="stepChanged" class="md-elevation-4" :md-active-step="step" md-vertical>
                    <md-step id="general" md-label="General" :md-error="tabs.general.errors > 0 ? 'This step needs attention' : null" :md-done="tabs.general.errors == 0">  
                            
                        <CRow>
                            <CCol sm="12" md="12" lg="4" xl="4">
                                <CRow>
                                    <CCol sm="6">
                                        <md-field :class="getValidationClass('name')">
                                            <label for="name">Name</label>
                                            <md-input name="name" id="name" autocomplete="given-name" v-model="driver.general.name" :disabled="sending" />
                                            <span class="md-error" v-if="!$v.driver.general.name.required">Name is required</span>
                                            
                                        </md-field>
                                    </CCol>
                                    <CCol sm="6">
                                        <md-field :class="getValidationClass('lastname')">
                                            <label for="last-name">Last Name</label>
                                            <md-input name="last-name" id="last-name" autocomplete="given-name" v-model="driver.general.lastname" :disabled="sending" />
                                            <span class="md-error" v-if="!$v.driver.general.lastname.required">Lastname is required</span>
                                            
                                        </md-field>
                                    </CCol>
                                </CRow>
                                <md-field :class="getValidationClass('email')">
                                    <label for="email">Email</label>
                                    <md-input type="email" name="email" id="email" autocomplete="off" v-model="driver.general.email" :disabled="sending" />
                                    <span class="md-error" v-if="!$v.driver.general.email.required">Email is required</span>
                                    <span class="md-error" v-else-if="!$v.driver.general.email.email">Invalid email</span>
                                </md-field>
                                <md-field >
                                    <label for="phone">Cell Phone</label>
                                    <md-input name="phone" id="phone" autocomplete="given-name" v-model="driver.general.phone" :disabled="sending" />
                                    <!--span class="md-error" v-if="!$v.driver.general.phone.required">Phone is required</span-->
                                    
                                </md-field>
                                <md-field >
                                    <label for="display-name">Display Name</label>
                                    <md-input name="display-name" id="display-name" autocomplete="given-name" v-model="driver.general.displayName" :disabled="sending" />
                                    <span class="md-helper-text">If empty display name will be name field</span>
                                    <!--span class="md-error" v-if="!$v.driver.general.displayName.required">Display name is required</span-->
                                    <!--span class="md-error" v-else-if="!$v.driver.general.displayName.minlength">Invalid display name</span-->
                                </md-field>
                                <md-field :class="getValidationClass('password')">
                                    <label for="password">Password</label>
                                    <md-input type="password" name="password" id="password" autocomplete="given-name" v-model="driver.general.password" :disabled="sending" />
                                    <span class="md-error" v-if="!$v.driver.general.password.required">Password is required</span>
                                    <span class="md-error" v-if="!$v.driver.general.password.minLength">Type at least 6 characters</span>
                                </md-field>
                            </CCol>
                        </CRow>
                    
                            
                        <md-button class="ml-0 md-raised" @click="step = 'fleet'">Next step</md-button>
                        
                    </md-step>
                    <md-step id="fleet" md-label="Fleet assignment" :md-error="tabs.fleet.errors > 0 ? 'This step needs attention' : null" :md-done="tabs.fleet.errors == 0">
                        <div v-if="vehicles.length === 0">
                            <md-empty-state
                                md-icon="drive_eta"
                                md-label="Add new vehicles"
                                md-description="Add new vehicles to your fleet to assing to your drivers">
                                <md-button class="md-primary md-raised" @click="newVehicle()">Add new vehicle</md-button>
                            </md-empty-state>
                        </div>
                        <div v-else>
                            <CRow >
                                <CCol sm="12" md="12" lg="4" xl="4">
                                    <md-field >
                                        <label for="fleet">Fleet</label>
                                        <md-select v-model="driver.vehicles" name="fleet" id="fleet" multiple @md-selected="vehicleSelected()">
                                            <md-option v-for="vehicle in vehicles" v-bind:key="vehicle.id" :value="vehicle.id" >{{vehicle.mainInfo.brand}} {{vehicle.mainInfo.carModel}} ({{vehicle.mainInfo.plate}})</md-option>
                                        </md-select>
                                        
                                        <!--span class="md-error" v-if="!$v.driver.vehicles.required">Assing at least one vehicle for this driver</span-->
                                                
                                    </md-field>
                                </CCol>
                            </CRow>
                            
                            <CRow>
                                <CCol sm="12" md="12" lg="4" xl="4" v-for="vehicle in selectedFleet" v-bind:key="vehicle.id">
                                    <md-card class="mb-4">
                                        <md-card-media v-if="vehicle.pictures.length > 0 ">
                                            <img :src="vehicle.pictures[0]" :alt="vehicle.name">
                                        </md-card-media>
                                        <md-card-header>
                                            <div class="md-subheading">{{vehicle.mainInfo.brand}} {{vehicle.mainInfo.carModel}}</div>
                                            <div class="md-subhead">Plate {{vehicle.mainInfo.plate}}</div>
                                        </md-card-header>
                                           
                                        
                                    </md-card>
                                </CCol>
    
                            </CRow>
                            <md-button class="md-raised" @click="step = 'general'">Previous step</md-button>
                            <md-button class="md-raised" @click="step = 'dmv'">Next step</md-button>
                            
                        </div>
                        
                    </md-step>
                    <md-step id="dmv" md-label="DMV" :md-error="tabs.dmv.errors > 0 ? 'This step needs attention' : null" :md-done="tabs.dmv.errors == 0">
                        <CRow>
                            <CCol sm="12" md="12" lg="4" xl="4">
                                
    
    
                                <md-datepicker name="driver-license" id="driver-license-expiration" v-model="driver.dmv.expiration" :disabled="sending" :md-model-type="Number">
                                    <label for="driver-license-expiration">DMV driver license expiration</label>
                                    <!--span class="md-error" v-if="!$v.driver.dmv.expiration.required">Driver license expiration is required</span-->
                                </md-datepicker>
                                
                                <md-field>
                                    <label for="driver-license-picture">DMV driver license picture</label>
                                    <md-file name="driver-license" id="driver-license-picture" v-model="driver.dmv.driverLicensePicture" :disabled="sending" @change="imageChanged($event, 'dmv')"/>
                                    <!--span class="md-error" v-if="!$v.driver.dmv.driverLicensePicture.required">Driver license picture is required</span-->
                                </md-field>
                                
                                
                                
                            </CCol>
                            <CCol sm="12" md="12" lg="4" xl="4">
                                <md-card class="license-preview mt-3">   
                                    <md-card-media-cover>
                                        <md-card-media class="text-center">
                                            <md-icon v-if="!driver.dmv.driverLicensePreview" class="md-size-5x">badge</md-icon>
                                            <div class="doc-asset picture-asset">
                                                <div class="pdf-preview-holder" @click="openDocDialog('DMV driver license', driver.dmv.driverLicensePreview, driver.dmv.image, driver.dmv.pdf)" v-if="driver.dmv.pdf && driver.dmv.driverLicensePreview">
                                                    <div class="doc-overlay">
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <vue-pdf-embed :page="1" :source="driver.dmv.driverLicensePreview"    />
                                                </div>
    
    
                                                <div class="img-preview-holder"  v-if="driver.dmv.image">
                                                    <div 
                                                        class="doc-overlay"
                                                        v-if="driver.dmv.image"
                                                        @click="openDocDialog('DMV driver license', driver.dmv.driverLicensePreview, driver.dmv.image, driver.dmv.pdf)"
                                                    >
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
    
                                                    <img :src="driver.dmv.driverLicensePreview ? driver.dmv.driverLicensePreview : ''" alt="" />
                                                </div>
                                                
    
                                            </div>
                                            <!--img v-else :src="this.driver.dmv.driverLicensePreview" alt=""-->
                                        </md-card-media>
                                        
                                        <md-card-header>
                                            <span class="md-title">{{ driver.dmv.driverLicense ? driver.dmv.driverLicense : 'DMV driver license'}}</span>
                                            <span class="md-subhead" v-if="driver.dmv.expiration">{{ driver.dmv.expiration | moment("MMMM Do YYYY") }}</span>
                                            <span class="md-subhead" v-else>Expiration date</span>
                                        </md-card-header>
    
                                        <!--md-card-actions>
                                            <md-button>Neither this</md-button>
                                            <md-button>Or this</md-button>
                                        </md-card-actions-->
                                       
                                    </md-card-media-cover>
                                </md-card>
                            </CCol>
                        </CRow>
                        <md-button class="md-raised" @click="step = 'fleet'">Previous step</md-button>
                        <md-button class="md-raised" @click="step = 'tlc'">Next step</md-button>
                        
                    </md-step> 
                    <md-step id="tlc" md-label="TLC" :md-error="tabs.tlc.errors > 0 ? 'This step needs attention' : null" :md-done="tabs.tlc.errors == 0">
                        <CRow>
                            <CCol sm="12" md="12" lg="4" xl="4">
                                <md-datepicker  name="tlc-driver-license-expiration" id="tlc-driver-license-expiration" v-model="driver.tlc.expiration" :disabled="sending" :md-model-type="Number">
                                    <label for="tlc-driver-license-expiration">TLC driver license expiration</label>
                                    <!--span class="md-error" v-if="!$v.driver.tlc.expiration.required">TLC Driver license expiration is required</span-->
                                </md-datepicker>
    
                                <md-field >
                                    <label for="tlc-driver-license-picture">TLC driver license picture</label>
                                    <md-file name="tlc-driver-license-picture" id="tlc-driver-license-picture" v-model="driver.tlc.driverLicensePicture" :disabled="sending" @change="imageChanged($event, 'tlc')"/>
                                    <!--span class="md-error" v-if="!$v.driver.tlc.driverLicensePicture.required">TLC driver license picture is required</span-->
                                </md-field>
                                 
                            </CCol>
                            <CCol sm="12" md="12" lg="4" xl="4">
                                <md-card class="license-preview mt-3">   
                                    <md-card-media-cover>
                                        <md-card-media class="text-center">
                                            <md-icon v-if="!this.driver.tlc.driverLicensePreview" class="md-size-5x">badge</md-icon>
                                            <div class="doc-asset picture-asset">
                                                <div class="pdf-preview-holder" @click="openDocDialog('TLC driver license', driver.tlc.driverLicensePreview, driver.tlc.image, driver.tlc.pdf)" v-if="driver.tlc.pdf && driver.tlc.driverLicensePreview">
                                                    <div class="doc-overlay">
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <vue-pdf-embed :page="1" :source="driver.tlc.driverLicensePreview"    />
                                                </div>
    
    
                                                <div class="img-preview-holder"  v-if="driver.tlc.image">
                                                    <div 
                                                        class="doc-overlay"
                                                        v-if="driver.tlc.image"
                                                        @click="openDocDialog('TLC driver license', driver.tlc.driverLicensePreview, driver.tlc.image, driver.tlc.pdf)"
                                                    >
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
    
                                                    <img :src="driver.tlc.driverLicensePreview ? driver.tlc.driverLicensePreview : ''" alt="" />
                                                </div>
                                                
    
                                            </div>
                                        </md-card-media>
                                        
                                        
                                        <md-card-header>
                                            <span class="md-title">{{ driver.tlc.driverLicense ? driver.tlc.driverLicense : 'TLC driver license'}}</span>
                                            <span class="md-subhead" v-if="driver.tlc.expiration">{{ driver.tlc.expiration | moment("MMMM Do YYYY") }}</span>
                                            <span class="md-subhead" v-else>Expiration date</span>
                                        </md-card-header>
    
                                        <!--md-card-actions>
                                            <md-button>Neither this</md-button>
                                            <md-button>Or this</md-button>
                                        </md-card-actions-->
                                       
                                    </md-card-media-cover>
                                </md-card>
                            </CCol>
                        </CRow>
                        <md-button class="md-raised" @click="step = 'dmv'">Previous step</md-button>
                        <md-button class="md-raised" @click="step = 'other-licenses'">Next step</md-button>
                    </md-step>
                    <md-step id="other-licenses" md-label="Other licenses" :md-error="tabs.otherLicenses.errors > 0 ? 'This step needs attention' : null" :md-done="tabs.otherLicenses.errors == 0">
                        <md-empty-state
                            v-if="driver.otherLicenses.length === 0"
                            
                            md-icon="badge"
                            md-label="Custom licenses and permissions"
                            md-description="Here you can create custom licenses that are not predifined in the documents tab .">
                            <md-button class="md-raised md-dense md-primary" @click="createLicense()">Create</md-button>
                        </md-empty-state>
                        <CRow v-else>
                            <CCol sm="12" md="12" lg="12" xl="12" v-for="(license, index) in driver.otherLicenses" v-bind:key="'otherLicenses-' + index ">
                               
                                <md-card class="uplimo-card" >
                                    <!--md-card-header >
                                            <div class="md-title">FH1</div>
                                    </md-card-header-->
                                    <md-card-content class="listed-doc-card">
                                        <div class="doc-asset">
    
                                            <div class="pdf-preview-holder" @click="openDocDialog(license.name, license.attachment.preview, license.attachment.image, license.attachment.pdf)" v-if="license.attachment.pdf && license.attachment.preview">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="license.attachment.preview"    />
                                            </div>
                                            <div class="pdf-preview-holder" @click="openDocDialog(license.name, license.attachment.storage, license.attachment.image, license.attachment.pdf)" v-else-if="license.attachment.storage != null && !license.attachment.preview">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="license.attachment.storage"    :loaded="pdfLoaded()"/>
                                            </div>
    
                                        
                                            <div class="img-preview-holder"  v-if="license.attachment.image">
                                                <div 
                                                    class="doc-overlay"
                                                    v-if="license.attachment.image && license.attachment.preview"
                                                    @click="openDocDialog(license.name, license.attachment.preview, license.attachment.image, license.attachment.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <div 
                                                    class="doc-overlay"
                                                    v-else-if="license.attachment.image && license.attachment.storage != null && !license.attachment.preview"
                                                    @click="openDocDialog(license.name, license.attachment.storage, license.attachment.image, license.attachment.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
    
                                                <img :src="license.attachment.preview ? license.attachment.preview : ''" alt="" />
                                                <img :src="license.attachment.storage ? license.attachment.storage : ''" alt="" />
    
                                            </div>
                                            
                                        </div>
                                    
                                        <div class="doc-data">
                                            <div class="md-title">{{license.name ? license.name : 'Name'}}</div>
                                            <CRow>
                                                <CCol sm="12">
                                                    <md-field :class="getValidationClass('otherLicenses.name',index)">
                                                        <label>Name</label>
                                                        <md-input v-model="license.name"></md-input>
                                                        <span class="md-error" v-if="!$v.driver.otherLicenses.$each[index].name.required">Name is required</span>
                                                    </md-field>
                                                    <md-field :class="getValidationClass('otherLicenses.file',index)">
                                                        <label >Attachment (Image or PDF)</label>
                                                        <md-file v-model="license.attachment.name" :disabled="sending" @change="docAttachLicenseChanged($event, index)"/>
                                                        <span class="md-error" v-if="!$v.driver.otherLicenses.$each[index].attachment.file.required">Attachment is required</span>
                                                    </md-field>
                                                    <md-datepicker v-model="license.expiration" :disabled="sending" :md-model-type="Number" :class="getValidationClass('otherLicenses.expiration',index)">
                                                        <label>Expiration Date</label>
                                                        <span class="md-error" v-if="!$v.driver.otherLicenses.$each[index].expiration.required">Expiration Date is required</span>
                                                    </md-datepicker>
                                                    <div class="mt-2" v-html="checkTimeToGo(license.expiration)" v-if="license.expiration"></div>
                                                    <md-button class="md-raised md-dense full-width-button" @click="removeLicense(index)">Remove license</md-button>
                                                </CCol>
                                                
                                            </CRow>
                                            
                                            
    
                                        </div>  
                                    </md-card-content>
                                </md-card>
                            </CCol>
                            <CCol sm="12" md="12" lg="12" xl="12">
                                <md-card class="uplimo-card">
                                    <md-card-header >
                                    </md-card-header>
                                    <md-card-content >
                                        <md-empty-state
                                
                                            
                                            md-icon="badge"
                                            md-label="Add new"
                                            md-description="Here you can create custom licenses that are not predifined in the documents tab .">
                                            <md-button class="md-raised md-dense md-primary" @click="createLicense()">Create</md-button>
                                        </md-empty-state>
                                    </md-card-content>
                                </md-card>
                            </CCol>
    
                        </CRow>
                        <md-button class="md-raised" @click="step = 'tlc'">Previous step</md-button>
                    </md-step> 
                    
                
                </md-steppers>
            </form>
            <md-dialog :md-active.sync="docDialog.active" :style="[dialogDimensions,dialogHeight]" :md-fullscreen="false" class="free-dialog" ref="dialog"  >
                <md-dialog-title v-if="docDialog.name">
                    {{ docDialog.name}}
                    
                </md-dialog-title>
                <md-divider class=" mb-4">
                </md-divider>
                <div class="modal-up-content text-center">
                    <img :src="docDialog.src" alt="" v-if="docDialog.image">
    
    
                    <md-button :disabled="docDialog.page == 1" @click="prevPdfPage" v-if="docDialog.pdf && docDialog.pageCount > 1">Prev</md-button>
                    <md-button :disabled="docDialog.page == docDialog.pageCount" @click="nextPdfPage" v-if="docDialog.pdf && docDialog.pageCount > 1">Next</md-button>
    
                    <div class="popup-pdf-holder" v-if="docDialog.pdf">
                        <vue-pdf-embed :source="docDialog.src"   :page="docDialog.page" ref="masterPdf" :width="pdfWidth"/>
                    </div>
                    
                </div>
                <md-dialog-actions>
                    <md-button @click="docDialog.active = false">Close</md-button>
                
                </md-dialog-actions>
            </md-dialog>
            <md-dialog
                    :style="[dialogDimensions]"
                    :md-active.sync="confirmDialog"
                >
                    <md-dialog-title>What would you like to do ?</md-dialog-title>
                    <md-dialog-content>
                        <p>You made changes in this driver. Choose your action </p>
                        <md-button class="full-width-button md-raised mb-2" @click="onCancel">Continue editing</md-button>
                        <md-button class="full-width-button md-raised md-accent mb-2" @click="onConfirm">Exit without saving</md-button>
                        <md-button class="md-primary full-width-button md-raised" @click="saveAndExit">Save &amp; exit</md-button>
                    </md-dialog-content>
    
                    
                    
            </md-dialog>
            <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!</md-snackbar>
            <md-snackbar :md-active.sync="error.show">
                {{ error.message }}
                <md-button class="md-primary" @click="error.show = false">OK</md-button>
            </md-snackbar>
        </div>
       
    </div>
</template>

<script>

import moment from 'moment'
import moment_timezone from "moment-timezone";
import imageCompression from "browser-image-compression"
import firebase from '../../Firebase'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { validationMixin } from 'vuelidate'
  import {
    required,
    email,
    minLength,
    maxLength,
    requiredIf
  } from 'vuelidate/lib/validators'

import axios from "./../../helpers/axios"

export default {
  name: 'NewCompanyDriver',
  components:{VuePdfEmbed},
  mixins: [validationMixin],
  data() {
    return {
        error: {
            show: false,
            message:null
        },
        fleetExists: false,
        confirmDialog: false,
        confirmLeave: false,
        isEdited: false,
        goTo: null,
        upload: {
            currentTitle: '',
            totalProgress: 0,
            uploadedDocs: [],
            currentFileProgress: 0,
            actualBytes: 0,
            totalBytes: 0,
            active: false
        },
        isBusy: false,
        tabs: {
            general: {
                errors: null,
            },
            fleet: {
                errors: null,
            },
            dmv: {
                errors: null,
            },
            tlc: {
                errors: null,
            },
            otherLicenses: {
                errors: null
            }
        },
        docDialog: {
            name: null,
            active: false,
            src: null,
            pdf: false,
            image: false,
            pageCount: null,
            page: 1

        },
        dialogLeft:0,
        role: '',
        userSaved: false,
        sending: false,
        lastUser: null,
        step: "general",
        vehicles: [],
        driver: {
            general: {
                name: null,
                lastname: null,
                email: null,
                displayName: null,
                password: null,
                phone: null,
            },
            dmv: {
                expiration: null,
                driverLicensePicture: null,
                file: null,
                driverLicenseUploadProgress : 0,
                driverLicensePreview: null,
                image: null,
                pdf: null
            },
            tlc: {
                expiration: null,
                driverLicensePicture: null,
                file: null,
                driverLicenseUploadProgress : 0,
                driverLicensePreview: null,
                image: null,
                pdf: null
            },
            otherLicenses: [],
            vehicles: []

        }
      
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    } ,
    selectedFleet: function() {
      let th = this
      return this.driver.vehicles.map(function(i) {
        for (let j = 0; j < th.vehicles.length; j++) {
            if(th.vehicles[j].id == i) {
                return th.vehicles[j]
            }
            
        }
        
      });
    },
    dialogDimensions () {
        console.log('this.$store.state.dialogLeft',this.$store.state.dialogLeft)
        return {
            //left: this.$parent.$parent.offsetLeft + 'px'
            left: this.$store.state.dialogLeft + 'px' ,
            width: this.$store.state.dialogWidth + 'px'
            //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
        }
    },
    dialogHeight() {
        console.log(window)
        console.log(window.innerHeight)
        
        return {
            height: window.innerHeight - this.headerHeight + 'px',
            top: this.headerHeight + 'px'
            //height: this.$store.state.sidebarNavHeight + 'px'
        }
    },
    pdfWidth () {
        return  (window.innerWidth - this.dialogLeft) * 0.9 
        
    },
    headerHeight() {
        return this.$store.state.headerHeight;
    },
  },
  methods: {
    newVehicle(){
      this.$router.push({path: `/app/fleet/new`})
    },
    async saveAndExit() {
        // this.isBusy = true;
        // if(this.validateAllSteps()){
        //     await this.update()
        //     this.onConfirm()
        // }
        // this.isBusy = false;
        this.confirmDialog = false
        this.fireCreate()
       
        
    },
    onCancel() {
        this.confirmDialog = false
    },
    onConfirm() {
        this.confirmLeave = true
        this.$router.push({path: this.goTo.path})
    },
    pdfLoaded(call){
        //console.log(call)
    },
    async uploadDocumentsAttachments() {
        let email = this.user.data.email ;
        let th = this;
       
        
        const promises = this.driver.otherLicenses.map( async (license, index) => {


            return new Promise(async (resolve, reject) => {


                const compressedFile = license.attachment.image ? await this.compressImage(license.attachment.file) : license.attachment.file
                th.upload.currentFileProgress = 0
                th.upload.currentTitle = `Currently uploading ${license.name}`
                let now = moment().format('x')
                const storageRef = this.$firebase.storage().ref()
                            .child(`drivers/`+ th.driver.general.email +`/other-licenses/${license.name + now}`)
                            .put(compressedFile);

                

                storageRef.on(`state_changed`,
                    snapshot => {
                        //this.driver[type].driverLicenseUploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                        license.uploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                        th.upload.currentFileProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                        th.upload.actualBytes = snapshot.bytesTransferred
                        th.upload.totalBytes = snapshot.totalBytes
                    }, 
                    error => {
                        console.log(error.message)
                        reject(error);
                    },
                    async () => {
                        const imgURL = await storageRef.snapshot.ref.getDownloadURL();
                        console.log("uploaded image: " + imgURL);
                        

                        // this.driver.otherLicenses[k].attachment.storage = uploadedMediaList[k]
                        // let dataToSave = this.driver.otherLicenses[k]
                        // dataToSave.attachment.preview = null
                        // delete dataToSave.attachment.file

                        this.upload.uploadedDocs.push(`${license.name} driver license`)

                        resolve(imgURL)
                        

                    }
                );
            })

                        

            // this.upload.uploadedDocs.push(`${type.toUpperCase()} driver license`)
        })

        return Promise.all(promises)
        .then((uploadedMediaList) => {
            console.log(uploadedMediaList, 'all');
            for (let k = 0; k < this.driver.otherLicenses.length; k++) {
                
                this.driver.otherLicenses[k].attachment.storage = uploadedMediaList[k]
                let dataToSave = this.driver.otherLicenses[k]
                dataToSave.attachment.preview = null
                delete dataToSave.attachment.file

                //this.vehicle.otherLicenses.push(dataToSave)
                
            }
            //this.vehicle.pictures = uploadedMediaList ;
        })
        .catch((err) => alert(err.code));
    },
    async fireCreate(){
        let th = this
       

        //let dmv = await this.uploadLicense('dmv')
        //let tlc = await this.uploadLicense('tlc')
        //await this.uploadDocumentsAttachments()
        

        if(this.validateAllSteps()) {
            this.isBusy = true;
            const driver = await this.createDriver()
            console.log(driver)
            if(driver.data.uid){
                this.upload.active = true;
                
                let dmv = await this.uploadLicense('dmv')
                let tlc = await this.uploadLicense('tlc')
                await this.uploadDocumentsAttachments()
                this.upload.currentTitle = null;
                this.upload.active = false;
                this.driver.general.uid = driver.data.uid
                let dataToSend = this.driver

                dataToSend.general.email = dataToSend.general.email.toLowerCase()

                let displayName ;
                if(!this.driver.general.displayName || this.driver.general.displayName === '') {
                    displayName = this.driver.general.name
                } else {
                    displayName = this.driver.general.displayName
                }

                dataToSend.general.displayName = displayName

                if(this.driver.dmv.expiration) {

                    dataToSend.dmv.expiration = parseInt(moment(this.driver.dmv.expiration).format('X'))
                }

                if(this.driver.tlc.expiration) {
                    dataToSend.tlc.expiration = parseInt(moment(this.driver.tlc.expiration).format('X'))
                }



                if(this.driver.otherLicenses.length > 0) {
                    for (let i = 0; i < this.driver.otherLicenses.length; i++) {
                        dataToSend.otherLicenses[i].expiration = parseInt(moment(this.driver.otherLicenses[i].expiration).format('X'))
                        
                    }
                }

                dataToSend.dmv.driverLicensePreview = null
                dataToSend.dmv.driverLicenseUploadProgress = 0
                dataToSend.tlc.driverLicensePreview = null
                dataToSend.tlc.driverLicenseUploadProgress = 0
                delete dataToSend.general.password


                firebase.firestore().collection('drivers').doc(this.driver.general.email).set(dataToSend)
                .then(function(docRef) {
                    th.sending = false;
                    th.isBusy = false;
                    th.confirmLeave = true;
                    th.$router.push({path: `/app/drivers/company-drivers`})
                })
                .catch(function(error) {
                    th.sending = false;
                    th.isBusy = false;
                    console.error("Error adding Tutorial: ", error);
                });
                // /drivers/email/dmv
                // /drivers/email/tlc
                // /drivers/email/other-licenses
                // /drivers/email/other-licenses/license-name + now()

            } else {
                this.error.message = driver.data.errorInfo.message
                this.error.show = true ;
                this.isBusy = false
            }
        }
    },
    
    openDocDialog(name,src,image,pdf) {
        this.docDialog.page = 1
        this.docDialog.name = name
        this.docDialog.src = src
        
        this.docDialog.image = image
        this.docDialog.pdf = pdf
        this.docDialog.active = true
        this.$nextTick(() => {
            
            let pdf = this.$refs.masterPdf
            
            
            if(pdf) {
                this.docDialog.pageCount = this.$refs.masterPdf.pageCount
            }
            this.$store.commit('centerDialog',window.innerWidth)
        })
        
    },
    docAttachLicenseChanged(e, index) {
        console.log(e)
        console.log(e.target.files[0].type)
        if(e.target.files[0].type == 'application/pdf') {
            this.driver.otherLicenses[index].attachment.pdf = true
            this.driver.otherLicenses[index].attachment.image = false
        } else {
            this.driver.otherLicenses[index].attachment.pdf = false
            this.driver.otherLicenses[index].attachment.image = true
        }
        console.log(index)
        this.driver.otherLicenses[index].attachment.file = e.target.files[0]
        this.driver.otherLicenses[index].attachment.preview = URL.createObjectURL(e.target.files[0]);
        
    },
    checkTimeToGo(date) {
        var startTime = moment();
        var endTime = moment(date); 
        var minutesDiff = endTime.diff(startTime, 'minutes');
        var daysDiff = endTime.diff(startTime, 'days');
        var ms = endTime.diff(startTime);
        var d = moment.duration(ms);
        let className ;
        
        if(daysDiff < 0  || minutesDiff < 0) {
            className = 'up-text-cancelled'
        } else if(daysDiff >= 0 && daysDiff <= 30) {
            className = 'up-text-caution'
        } else if(daysDiff > 30) {
            className = 'up-text-confirmed'
        }

        let payload ; 
        let text = minutesDiff >= 0 ? 'Expires ' : 'Expired '  
        payload = '<div class="text-uppercase time-to-go ' + className + '">'+ text + endTime.fromNow() + '</div>'
        return payload ;
        
    },
    createLicense(){
        let license = {
            name: null,
            expiration: null,
            uploadProgress:0,
            attachment: {
                preview: null,
                file:null,
                name: null,
                pdf: false,
                image: false
            }
        }

        this.driver.otherLicenses.push(license)
    },
    removeLicense(index) {
        this.driver.otherLicenses.splice(index, 1);
    },
    vehicleSelected() {
    },
    async compressImage(file) {
       
       
        return new Promise(async (resolve, reject) => {
        
            const imageFile = file;
            console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
            console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);     


            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1200,
                useWebWorker: true
            }

            
            
            imageCompression(imageFile, options)
            .then(function (compressedFile) {
                console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                resolve(compressedFile); // write your own logic
            })
            .catch(function (error) {
                reject(error)
            });
        });
            
            
          
            //await uploadToServer(compressedFile); // write your own logic
       
    },

    async uploadLicense(type) {
        let th = this;
        return new Promise(async (resolve, reject) => {

            if(this.driver[type].file) {
                
                console.log("Uploading image ...");
                th.upload.currentFileProgress = 0
                this.upload.currentTitle = `Currently uploading ${type}`
                console.log(type);
                this.driver[type].storage = null;   
                const compressedFile = this.driver[type].image ? await this.compressImage(this.driver[type].file) : this.driver[type].file
                
    
                const storageRef = this.$firebase.storage().ref()
                                    .child(`drivers/${this.driver.general.email}/${type}-driver-license/${this.driver[type].driverLicensePicture}`)
                                    .put(compressedFile);
                storageRef.on(`state_changed`,
                    snapshot => {
                        this.driver[type].driverLicenseUploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                        th.upload.currentFileProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                        th.upload.actualBytes = snapshot.bytesTransferred
                        th.upload.totalBytes = snapshot.totalBytes
                    }, 
                    error => {
                        console.log(error.message)
                        reject(error);
                    },
                    async () => {
                        const imgURL = await storageRef.snapshot.ref.getDownloadURL();
                        console.log("uploaded image: " + imgURL);
                        this.driver[type].storage = imgURL;
                        
                        delete this.driver[type].file
    
                        this.upload.uploadedDocs.push(`${type.toUpperCase()} driver license`)
                        resolve(imgURL);
    
                    }
                );
            } else {
                resolve()
            }
        });
    },
    imageChanged(e, type) {
       
        this.driver[type].file = e.target.files[0]
        console.log(this.driver[type].file)
        if(this.driver[type].file.type == 'application/pdf') {
            this.driver[type].pdf = true
            this.driver[type].image = false
        } else {
            this.driver[type].pdf = false
            this.driver[type].image = true
        }
        this.driver[type].driverLicensePreview = URL.createObjectURL(e.target.files[0]);
       
    },
    nextOrSave(){
        console.log(this.step);
        console.log(this.$v);
        switch(this.step) {
            case 'general':
                
                if(this.validateStep(this.step)){
                    this.step = 'dmv'
                } else {
                    this.$v.driver.$touch()
                    // this.$v.driver.general.name.$touch();
                    // this.$v.driver.general.lastname.$touch();
                    // this.$v.driver.general.email.$touch();
                    // this.$v.driver.general.displayName.$touch();
                    // this.$v.driver.general.password.$touch();
                }
                break
            case 'fleet':
                if(this.validateStep(this.step)){
                    // Save
                    this.step = 'dmv'
                } else {
                    this.$v.driver.vehicles.$touch();
                    //this.$v.driver.tlc.driverLicensePicture.$touch();
                }
            break
            case 'dmv':
                if(this.validateStep(this.step)){
                    this.step = 'tlc'
                } else {
                    console.log(false);
                    this.$v.driver.dmv.expiration.$touch();
                    this.$v.driver.dmv.driverLicensePicture.$touch();
                }
                break
            case 'tlc':
                if(this.validateStep(this.step)){
                    // Save
                } else {
                    this.$v.driver.tlc.expiration.$touch();
                    this.$v.driver.tlc.driverLicensePicture.$touch();
                }
                break
            
        }
    },
    validateAllSteps(){
        let validSteps = [];
        let firstCorrectableStep = undefined;
        let steps = ['general','fleet','dmv','tlc','otherLicenses'] ;
        for (let i = 0; i < steps.length; i++) {
            if(this.validateStep(steps[i])) {
                validSteps.push(true)
            } else {
                validSteps.push(false)
                if(firstCorrectableStep === undefined) {
                    firstCorrectableStep = steps[i] ;
                }
            }
                    
        }
        
        if(validSteps.includes(false)) {
            
            this.step = firstCorrectableStep
            //this.mainActiveTab = 'general' ;
            this.$v.$touch();
            return false;
        } else {
            console.log('All steps are valid')
            return true
            
            //this.add();
            //this.saveVehicle()
        }

        
    },
    validateStep(step){
        switch(step) {
            case 'general':
                this.tabs.general.errors = 0 ;
                let name,lastname,phone,email,displayName,password ;
                name = this.$v.driver.general.name.$invalid;
                lastname = this.$v.driver.general.lastname.$invalid;
                email = this.$v.driver.general.email.$invalid;
                //phone = this.$v.driver.general.phone.$invalid;
                //displayName = this.$v.driver.general.displayName.$invalid;
                password = this.$v.driver.general.password.$invalid;

                name ? this.tabs.general.errors++ : false;
                lastname ? this.tabs.general.errors++ : false;
                email ? this.tabs.general.errors++ : false;
                //phone ? this.tabs.general.errors++ : false;
                //displayName ? this.tabs.general.errors++ : false;
                password ? this.tabs.general.errors++ : false;


                return !name && !lastname && !email && !password ? true : false ;
            
            case 'fleet':
                let vehicles;
                this.tabs.fleet.errors = 0 ;

                //vehicles = this.$v.driver.vehicles.$invalid

                //vehicles ? this.tabs.fleet.errors++ : false;

                //return !vehicles ? true : false
                return true
                
            case 'dmv':
                let driverLicense, expiration, driverLicensePicture ;
                this.tabs.dmv.errors = 0 ;


                //expiration = this.$v.driver.dmv.expiration.$invalid;
                //driverLicensePicture = this.$v.driver.dmv.driverLicensePicture.$invalid;

                //expiration ? this.tabs.dmv.errors++ : false;
                //driverLicensePicture ? this.tabs.dmv.errors++ : false;


                //return !driverLicense && !expiration && !driverLicensePicture ? true : false ;
                return true
                
            case 'tlc':
                let tlcDriverLicense, tlcDriverLicenseExpiration, tlcDriverLicensePicture ;
                this.tabs.tlc.errors = 0 ;


                //tlcDriverLicenseExpiration = this.$v.driver.tlc.expiration.$invalid;
                //tlcDriverLicensePicture = this.$v.driver.tlc.driverLicensePicture.$invalid;

                //tlcDriverLicenseExpiration ? this.tabs.tlc.errors++ : false;
                //tlcDriverLicensePicture ? this.tabs.tlc.errors++ : false;

                //return !tlcDriverLicense && !tlcDriverLicenseExpiration && !tlcDriverLicensePicture ? true : false ;
                return true
            case 'otherLicenses':
                this.tabs.otherLicenses.errors = 0 ;
                let otherErrors = []
                for (let i = 0; i < this.driver.otherLicenses.length; i++) {
                    
                    let licName,expiration,file;
                    licName = this.$v.driver.otherLicenses.$each[i].name.$invalid;
                    expiration = this.$v.driver.otherLicenses.$each[i].expiration.$invalid;
                    file = this.$v.driver.otherLicenses.$each[i].attachment.file.$invalid;

                    licName ? this.tabs.otherLicenses.errors++ : false;
                    expiration ? this.tabs.otherLicenses.errors++ : false;
                    file ? this.tabs.otherLicenses.errors++ : false;

                    !licName && !expiration && !file  ? otherErrors.push(true) : otherErrors.push(false) ;
                    
                }

                

                return otherErrors.includes(false) ? false : true ;
                
        }
    },
    stepChanged(id)
    {
        this.validateAllSteps()
        this.step = id;
       
    },
    getValidationClass (fieldName,index) {
        let field = null;
        //const field = this.$v[fieldName]
        if(
            fieldName == 'email' 
            || fieldName == 'displayName' 
            || fieldName == 'password' 
            || fieldName == 'name' 
            || fieldName == 'phone' 
            || fieldName == 'lastname' 
            ) {
            // General Step fields
            field = this.$v.driver.general[fieldName]
        } else if(fieldName == 'fleet') {
            
            field = this.$v.driver.vehicles
        } else if (
            fieldName == 'dmv.driverLicense' 
            || fieldName == 'dmv.expiration' 
            || fieldName == 'dmv.driverLicensePicture' 
            ) {
            let fieldSplit = fieldName.split('dmv.')[1];
            field = this.$v.driver.dmv[fieldSplit]
        } else if (
            fieldName == 'tlc.driverLicense' 
            || fieldName == 'tlc.expiration' 
            || fieldName == 'tlc.driverLicensePicture' 
        ) {
            let fieldSplit = fieldName.split('tlc.')[1];
            field = this.$v.driver.tlc[fieldSplit]
        } else if(
            fieldName === 'otherLicenses.name'
            || fieldName === 'otherLicenses.file'
            || fieldName === 'otherLicenses.expiration'

        
        ) {
            
            let fieldSplit = fieldName.split('otherLicenses.')[1];
            if(fieldSplit == 'file') {
                field = this.$v.driver.otherLicenses.$each[index].attachment[fieldSplit]
            } else {
                field = this.$v.driver.otherLicenses.$each[index][fieldSplit]
            }
            
        }

        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
    clearForm () {
        this.$v.$reset()
        this.driver = null;
        this.role = null
        
    },
    
   
    async createDriver() {
      // Create user in API
      // With return resolved use email to upload storage
      // Save urls into objects
      // Save final object to firestore
      let th = this;

      let displayName ;
      if(!this.driver.general.displayName || this.driver.general.displayName === '') {
        displayName = this.driver.general.name
      } else {
        displayName = this.driver.general.displayName
      }
      let lowerCaseEmail = this.driver.general.email
      let data = {
            
        displayName: displayName, 
        password: this.driver.general.password, 
        email: lowerCaseEmail.toLowerCase(), 
        ownerEmail: this.user.data.email,
        ownerUid: this.user.data.uid,
        role: 'driver',
        subrole: 'company'
        
        // driver: {
        //     dmv: {
        //         //dmvDriverLicense: this.driver.dmv.driverLicense,
        //         dmvExpiration: this.driver.dmv.expiration,
        //         dmvFile: await toBase64(this.driver.dmv.file),
        //     },
        //     tlc: {
        //         //tlcDriverLicense: this.driver.tlc.driverLicense,
        //         tlcExpiration: this.driver.tlc.expiration,
        //         tlcFile: await toBase64(this.driver.tlc.file),
        //     },
        //     vehicles: this.driver.vehicles,
        //     otherLicenses: this.driver.otherLicenses
        // },
      }


      
      return new Promise((resolve, reject) => {
            axios.post('drivers',data).then(response =>{
                console.log(response);
                th.sending = false
                resolve(response)
                //th.$router.push({path: '/users/drivers'})
            }).catch(e => {
                console.log(e);
                th.sending = false
                reject(e)
                
            }) 
      });
      
      
      
    },
    myEventHandler(e) {
        // your code for handling resize...
        console.log('resized')
        this.$store.commit('centerDialog',window.innerWidth)
    },

  },
  created () {

    this.$store.commit('restoreBrowserTime')

    firebase.firestore().collection('users').doc(this.user.data.uid).collection('fleet').orderBy("order").onSnapshot((querySnapshot) => {

        this.vehicles = [];
        querySnapshot.forEach((doc) => {

            let dataToSave = doc.data();
            dataToSave.id = doc.id ;
            this.vehicles.push(dataToSave);
         

        });

        this.total = this.vehicles.length;
        this.total === 0 ? this.fleetExists = false : this.fleetExists = true
    
        this.isBusy = false; 
      
    });


    window.addEventListener("resize", this.myEventHandler);
    
  },
  validations: {
    driver: {
        general: {
            name: {
                required
            },
            lastname: {
                required
            },
            phone: {
                required
            },
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(6)
            },
            displayName: {
                required,
                minLength: minLength(3)
            }
        },
        vehicles: {
            required,
            minLength: minLength(1),
        },
        dmv: {
              
              expiration: {
                  required
              },
              driverLicensePicture: {
                  required
              }
        },
        tlc: {
           
            expiration: {
                required
            },
            driverLicensePicture: {
                required
            }
        },
        otherLicenses: {
            $each: {
                name: {
                    required
                },
                expiration: {
                    required
                },
                attachment: {
                    //preview: null,
                    file:{
                        required
                    },
                    //pdf: false,
                    //image: false
                }
            }
        }
    }
  },
  destroyed() {
        
    window.removeEventListener("resize", this.myEventHandler);
  },
  beforeRouteLeave(to, from, next) {

    if (this.isEdited && !this.confirmLeave) {
        this.confirmDialog = true;
        console.log(to);
        this.goTo = to;
        return 
        if (!window.confirm("Leave without saving?")) {
            return;
        }
    }
    next();

  },
  watch: {
    'driver': {
        handler(val,oldVal) {
            console.log('val',val)
            console.log('oldVal',oldVal)
            if(oldVal != null) {
                console.log('Driver watch')
                this.isEdited = true;
            }
        },
        deep: true

    },
    '$store.state.sidebarMinimize': function() {
        this.$store.commit('centerDialog',window.innerWidth)
    },
    '$store.state.sidebarShow': function(){
        this.$store.commit('centerDialog',window.innerWidth)
    },
  },
}
</script>